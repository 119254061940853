import React from 'react';
import PropTypes from 'prop-types';
import ResultsView from 'ToolboxComponents/webapp/pages/results-view/results-view';


const CatalogsView = ({ addFavorite, categoryName, categoryDescription, deleteFavorite, products, isEvent }) => {
  return (
    <ResultsView
      products={products}
      addFavorite={event => addFavorite(event)}
      deleteFavorite={event => deleteFavorite(event)}
      hasFilters={false}
      isSelectedProduct={true}
      isEvent={isEvent}
      specialHeader= {{
        title: <>{categoryName}</>,
        teaser: <>{categoryDescription}</>,
      }}
    />
  );
};

CatalogsView.propTypes = {
  addFavorite: PropTypes.func.isRequired,
  categoryName: PropTypes.string,
  deleteFavorite: PropTypes.func.isRequired,
  products: PropTypes.array.isRequired,
};

export default CatalogsView;
