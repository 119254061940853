import React, { useContext, useEffect , useState } from 'react';
import { Helmet } from "react-helmet";
import { useHistory } from 'react-router-dom'
import { AccountContext, GeolocationContext, LanguageContext, WhiteLabelContext } from "ToolboxUtils/web/context/context";
import apiChuchoteurs from 'ToolboxUtils/web/api/api-chuchoteurs';
import useIsContentLoaded from 'ToolboxUtils/web/hooks/use-is-content-loaded';
import CatalogsView from 'ToolboxComponents/webapp/pages/catalogs-view/catalogs-view';
import getPath from 'Utils/web/get-path';
import Loader from 'ToolboxComponents/commons/loader/loader';
import getMetaDescription from 'ToolboxUtils/web/get-meta-description';


import { PAGE_LOAD_WAITING } from 'Params/globals';

const Catalogs = props => {
  const history = useHistory();
  const [account, setAccount] = useContext(AccountContext).usePath();
  const [coordinates] = useContext(GeolocationContext).usePath('coordinates');
  const [geolocation] = useContext(GeolocationContext).usePath();
  const [language] = useContext(LanguageContext).usePath();
  const [whiteLabel] = useContext(WhiteLabelContext).usePath();

  const [category] = useState(props.history.location.pathname.split('/')[1].length !== 2 ? props.history.location.pathname.split('/')[3] : props.history.location.pathname.split('/')[4]);
  const [categoryName, setCategoryName] = useState(null);
  const [categoryTitle, setCategoryTitle] = useState(null);
  const [categoryDescription, setCategoryDescription] = useState(null);
  const [isError, setIsError] = useState(false);
  const [isEvent, setIsEvent] = useState(false);
  const [isLoaderCountDownSet, setLoaderCountDown] = useState(false);
  const [isLoaderDisplayed, showLoader] = useState(false);
  const [pageInitTime, setPageInitTime] = useState(Date.now());
  const [pathName] = useState(props.history.location.pathname);
  const [products, setProducts] = useState([]);
  const [productsLoaded, setProductsLoaded] = useState(false);
  const [type] = useState(props.history.location.pathname.split('/')[1].length !== 2 ? props.history.location.pathname.split('/')[2] : props.history.location.pathname.split('/')[3]);

  if (!isLoaderCountDownSet) {
    setLoaderCountDown(true);
    setTimeout(function(){
      if (!productsLoaded) {
        showLoader(true);
      }
    }, PAGE_LOAD_WAITING - (Date.now() - pageInitTime));
  }

  useEffect(() => {
    return () => {
      setLoaderCountDown(false);
      setPageInitTime(null);
    }
  }, []);

  useEffect(() => {
    if ((pathName.includes('envie') || pathName.includes('desire') || pathName.includes('tag')) && category !== undefined) {
      if (whiteLabel && whiteLabel.id) {
        setProductsLoaded(false);
        async function getCatalog() {
          try {
            const products = await apiChuchoteurs.get(`/products`, {
              params: {
                budgetId: 1,
                category: category,
                filter: 'search-products',
                locationId: whiteLabel.isInZone ? 0 : whiteLabel.city.id,
                isGeolocated: geolocation.isGeolocated,
                isInZone: whiteLabel.isInZone,
                language: language,
                algo: whiteLabel.config.algo && whiteLabel.config.algo.event, 
                momentId: 1,
                profileId: 1,
                searchLatitude: coordinates.latitude,
                searchLongitude: coordinates.longitude,
                time: new Date().getTime(),
                transportId: 1,
                type: type,
                userLatitude: coordinates.latitude,
                userLongitude: coordinates.longitude,
                whiteLabelId: whiteLabel.id,
              }
            });
            setProducts(products);
            setProductsLoaded(true);
          } catch (err) {
            setIsError(true);
            console.log(err);
          }
        }
        getCatalog();
        async function getDesire() {
          try {
            const desire = await apiChuchoteurs.get(`/desires`, {
              params: {
                desire: category,
                filter: 'search-name',
                language: language,
                whiteLabelId: whiteLabel.id,
              }
            });
            setCategoryName(desire.name);
            setCategoryTitle(desire.metaTitle);
            setCategoryDescription(desire.metaDescription);
            setIsEvent(desire.isEvent);
          } catch (err) {
            console.log(err);
          }
        }
        async function getTag() {
          try {
            const tag = await apiChuchoteurs.get(`/tags`, {
              params: {
                filter: 'search-name',
                language: language,
                tag: category,
                whiteLabelId: whiteLabel.id,
              }
            });
            setCategoryName(tag);
          } catch (err) {
            console.log(err);
          }
        }
        if (type === 'tag') {
          getTag();
        } else {
          getDesire();
        }
      }
    } else {
      history.push(getPath('home', whiteLabel, language));
    }
  }, [coordinates, whiteLabel]);

  useIsContentLoaded(productsLoaded);
  
  return (
    <>
      <Helmet>
        {categoryTitle && <title>{`${categoryTitle + ' - ' + whiteLabel.name}`}</title>}
        {categoryDescription && <meta name="description" content={getMetaDescription(categoryDescription)} />}
      </Helmet>
      {productsLoaded
        ? <CatalogsView
            addFavorite={async (id) => {
              if (account && account.id) {
                try {
                  const favorite = await apiChuchoteurs.post(`/accounts/${account.id}/products/${id}/favorites`, {
                    token: localStorage.token
                  })
                  updateFav(id);
                } catch(err) {
                  apiChuchoteurs.post(`/logs`, {
                    body: {error: {...err, type: 'front'}}
                  })
                }
              } else {
                openSignInModal(true);
              }
            }}
            categoryName={categoryName}
            categoryDescription={categoryDescription}
            deleteFavorite={async (id) => {
              if (account && account.id) {
                try {
                  const favorite = await apiChuchoteurs.delete(`/accounts/${account.id}/products/${id}/favorites`, {
                    token: localStorage.token
                  })
                  updateFav(id);
                } catch(err) {
                  apiChuchoteurs.post(`/logs`, {
                    body: {error: {...err, type: 'front'}}
                  })
                }
              } else {
                openSignInModal(true);
              }
            }}
            isEvent={isEvent}
            products={products}
          />
        : isError
          ? <p style={{display: 'flex', justifyContent: 'center', height: '80vh', alignItems: 'center', fontSize: '30px'}} >Page 404</p>
          : isLoaderDisplayed
            ? <Loader />
            : <></>
      }
    </>
  );
}

export default Catalogs;
